<template>
  <b-overlay :show="isLoading">
    <vuetable
      ref="userstable"
      :api-mode="false"
      :fields="fields"
      :data="logs"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="editUser(props.rowData)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="deleteUser(props.rowData)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable,
  },
  props: ["user"],
  data() {
    return {
      isLoading: false,
      fields: ["email", "ip", "message", "created_at", "updated_at"],
    };
  },
  watch: {
    user() {
      this.doFetchAll();
    },
  },
  computed: {
    logs() {
      return this.$store.getters["Logs/all"].data;
    },
  },
  methods: {
    onPaginationData(data) {
      console.log(data);
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Logs/fetchAll", this.user);
      this.isLoading = false;
    },
  },
  async created() {
    this.doFetchAll();
  },
};
</script>
<style lang="scss"></style>
